import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { formatedDate, formatedPrice, intToSituacaoCr, intToSituacaoDisparoColor, labelSituacaoCr } from "../../../utils";
import { useRef, useState } from "react";
import { toast } from 'react-toastify';
import { excluir_contas_pagar } from "../../../routes_api";
import { colorError } from "../../../styles/theme";
import { GiReceiveMoney } from "react-icons/gi";
import { ButtonFilter } from "../../../styledcomponents";
import { model_contas_pagar } from "../../../models";
import { Pagamento } from "./Pagamento";

export const ContasPagar = () => {
    const refPadrao = useRef<any>(null);
    const refPagamento = useRef<any>(null);

    const [filters, setFilters] = useState({ abertos: true, pagos: false, vencidos: true, vigencia: true, });

    const toggleFilter = (filterName: string) => {
        setFilters((prevFilters: any) => ({ ...prevFilters, [filterName]: !prevFilters[filterName] }));
        refPadrao.current.setRefreshFilter(true);
    };

    const receberClick = (rowData: any) => {
        if (rowData.situacao !== 0)
            toast.error('Recurso disponível apenas para registros em "Aberto".');
        else
            refPagamento.current.openModal(rowData);
    }

    const onBeforeEdit = (register: any) => {
        if (register.situacao !== 0) {
            return { sucess: false, message: 'Permitido editar somente registros em "Aberto".' };
        }
        if (register.valorPago !== 0) {
            return { sucess: false, message: 'Registro já foi pago parcialmente, não é possível editá-lo.' };
        }
        return { sucess: true };
    }

    const functionsGrid: Array<any> = [
        { icon: <GiReceiveMoney />, color: '#0F766E', onClick: receberClick }
    ]

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'fornecedor', header: 'Cliente', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'numDocumento', header: 'Número Doc.', sortable: true, style: { width: '125px' } },
        { field: 'emissao', header: 'Emissão', sortable: true, body: (e: any) => formatedDate(e.emissao), style: { width: '125px' } },
        { field: 'vencimento', header: 'Vencimento', sortable: true, body: (e: any) => formatedDate(e.vencimento), style: { width: '125px' } },
        { field: 'valor', header: 'Valor', sortable: true, body: (e: any) => formatedPrice(e.valor), style: { width: '100px' } },
        { field: 'valorAberto', header: 'Valor Aberto', sortable: true, body: (e: any) => formatedPrice(e.valorAberto), style: { width: '100px' } },
        { field: 'valorPago', header: 'Valor Pago', sortable: true, body: (e: any) => formatedPrice(e.valorPago), style: { width: '100px' } },
        { field: 'quitacao', header: 'Quitação', sortable: true, body: (e: any) => formatedDate(e.quitacao), style: { width: '100px' } },
        { field: 'situacao', header: 'Situação', body: (e: any) => labelSituacaoCr(e.situacao), style: { width: '75px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '6rem', width: '6rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        const filterLower = filter.toLowerCase();
        return [
            value.fornecedor,
            value.contador,
            value.numDocumento,
            formatedDate(value.emissao),
            formatedDate(value.vencimento),
            formatedPrice(value.valor),
            formatedPrice(value.valorAberto),
            formatedPrice(value.valorPago),
            formatedDate(value.quitacao),
            intToSituacaoCr(value.situacao)
        ].some((field) => String(field).toLowerCase().includes(filterLower));
    }

    const FilterButton = ({ active, label, onClick, color }: { active: boolean; label: string; onClick: () => void; color: string }) => (
        <ButtonFilter active={active} activeColor={color} onClick={onClick}>
            {label}
        </ButtonFilter>
    );

    const filterOptions = () => (
        <div style={{ width: '100%', padding: '10px 0px 5px' }}>
            <span style={{ fontSize: '14px', fontWeight: '500' }}>Filtrar por: </span>
            <FilterButton active={filters.abertos} label="Abertos" onClick={() => toggleFilter('abertos')} color={intToSituacaoDisparoColor(0)} />
            <FilterButton active={filters.pagos} label="Pagos" onClick={() => toggleFilter('pagos')} color={intToSituacaoDisparoColor(1)} />
            {filters.abertos && (
                <>
                    <FilterButton active={filters.vigencia} label="Em vigência" onClick={() => toggleFilter('vigencia')} color={'#1ca19b'} />
                    <FilterButton active={filters.vencidos} label="Vencidos" onClick={() => toggleFilter('vencidos')} color={colorError} />
                </>
            )}
        </div>
    );

    const applyFilter = (value: any) => {
        const isVencido = new Date(value.vencimento) < new Date();
        const isEmVigencia = new Date(value.vencimento) >= new Date();

        return (
            (filters.pagos && value.situacao === 1) ||
            (filters.abertos && value.situacao === 0 && ((filters.vencidos && isVencido) || (filters.vigencia && isEmVigencia)))
        );
    }

    return (
        <>
            <BsTelaPadrao
                ref={refPadrao}
                sortcolumn={'contador'}
                sortcolumnorder={1}
                columns={cols}
                model={model_contas_pagar}
                onBeforeEdit={onBeforeEdit}
                prefixApiDelete={excluir_contas_pagar}
                onFilterRegisters={onFilter}
                customHeader={filterOptions}
                customFilter={applyFilter}
            />
            <Pagamento ref={refPagamento} success={() => refPadrao.current.refresh()} />
        </>
    );
}
